var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var ENV;
(function (ENV) {
    ENV.INSTAGRAM_ID = '3005253145';
})(ENV || (ENV = {}));
/// <reference path="../typings/jquery/jquery.d.ts" />
/// <reference path="../typings/bootstrap/bootstrap.d.ts" />
var Gallery;
(function (Gallery) {
    function _renderImages(data) {
        var path = data.path;
        var collectionImages = data.gallery;
        for (var index = 0; index < collectionImages.length; index++) {
            var templateIndicators = "<li data-target=\"#carousel-example-generic\" data-slide-to=\"" + index + "\" class=\"" + (index == 0 ? 'active' : '') + "\"></li>";
            $('.carousel-indicators').append(templateIndicators);
            var templateInner = "\n                           <div class=\"item " + (index == 0 ? 'active' : '') + "\">\n                <img src=\"" + path + collectionImages[index].image + "\" width=\"100%\" alt=\"" + collectionImages[index].title + "\">\n                <div class=\"carousel-caption\">\n                  " + collectionImages[index].title + "\n                </div>\n              </div>";
            $('.carousel-inner').append(templateInner);
            $('.carousel').carousel();
        }
    }
    function init(data) {
        _renderImages(data);
    }
    Gallery.init = init;
})(Gallery || (Gallery = {}));
/// <reference path="../typings/jquery/jquery.d.ts" />
/// <reference path="../typings/ractive/ractive.d.ts" />
$(function () {
    function getWidth() {
        return window.innerWidth;
    }
    function getValue() {
        return (getWidth() * 200) / 500;
    }
    function setHeight() {
        var element = $('#video');
        element.css('minHeight', getValue() + 'px');
        element.children('.hero-shadow').css('minHeight', getValue() + 'px');
        // $('video').height(getValue() + 'px');
    }
    $(window).resize(function () {
        setHeight();
    });
    setHeight();
    //950 / 540
});
Ractive.decorators.drag = function (node, keypath) {
    var ractive = this, begin, startPos;
    node.addEventListener('mousedown', start, false);
    node.addEventListener('touchstart', startTouch, false);
    window.addEventListener('resize', resize);
    function resize() {
        ractive.set(keypath, {
            left: 0,
            top: 0
        });
    }
    function listenOnDocument() {
        document.addEventListener('mousemove', move, false);
        document.addEventListener('mouseup', unlistenOnDocument, false);
        //Mobile ======================================= >>
        document.addEventListener('touchmove', moveTouch, false);
        document.addEventListener('touchend', unlistenOnDocument, false);
    }
    function unlistenOnDocument() {
        document.removeEventListener('mousemove', move, false);
        document.removeEventListener('mouseup', end, false);
        //Mobile ==================================== >>
        document.removeEventListener('touchmove', moveTouch, false);
        document.removeEventListener('touchend', end, false);
        window.removeEventListener('resize', resize, false);
    }
    function start(e) {
        //        begin = { x: e.x , y: e.y }
        begin = { x: e.clientX, y: e.clientY };
        startPos = ractive.get(keypath);
        startPos = { x: startPos.left, y: startPos.top };
        ractive.set('width', node.children[0].offsetWidth);
        listenOnDocument();
        e.preventDefault();
        e.stopPropagation();
    }
    function move(e) {
        //        var left = (startPos.x + (e.x - begin.x)) > 0 ? 0 : (startPos.x + (e.x - begin.x));
        var left = (startPos.x + (e.clientX - begin.x)) > 0 ? 0 : (startPos.x + (e.clientX - begin.x));
        var listWidth = node.children[0].offsetWidth;
        var width = window.innerWidth;
        var leftP = (listWidth + left + 30) < width ? -listWidth + width - 40 : left;
        ractive.set(keypath, {
            left: leftP,
            //            top: startPos.y + (e.y - begin.y)
            top: startPos.y + (e.clientY - begin.y)
        });
        e.preventDefault();
        e.stopPropagation();
    }
    function startTouch(e) {
        begin = { x: e.touches[0].screenX, y: e.touches[0].screenY };
        startPos = ractive.get(keypath);
        startPos = { x: startPos.left, y: startPos.top };
        ractive.set('width', node.children[0].offsetWidth);
        listenOnDocument();
        e.preventDefault();
        e.stopPropagation();
    }
    function moveTouch(e) {
        var left = (startPos.x + (e.touches[0].screenX - begin.x)) > 0 ? 0 : (startPos.x + (e.touches[0].screenX - begin.x));
        var listWidth = node.children[0].offsetWidth;
        var width = window.innerWidth;
        var leftP = (listWidth + left + 30) < width ? -listWidth + width - 40 : left;
        ractive.set(keypath, {
            left: leftP,
            top: startPos.y + (e.touches[0].screenY - begin.y)
        });
        e.preventDefault();
        e.stopPropagation();
    }
    function end() {
        unlistenOnDocument();
    }
    return {
        update: function (pos) {
            position = pos;
        },
        teardown: function () {
            node.removeEventListener('mousedown', start, false);
            node.removeEventListener('touchstart', startTouch, false);
            window.removeEventListener('resize', resize);
            end();
        }
    };
};
/// <reference path="../typings/jquery/jquery.d.ts" />
/// <reference path="../typings/ractive/ractive.d.ts" />
var imageurl = {
    books: 'img/books/',
    author: 'img/authors/'
};
var MODAL = (function () {
    function MODAL() {
    }
    MODAL.prototype.get = function (property) {
        return this[property];
    };
    MODAL.prototype.set = function (property, value) {
        this[property] = value;
    };
    return MODAL;
}());
var Collection = (function () {
    function Collection(modal) {
        this._config = {};
        this.modal = modal;
        this.list = [];
    }
    Collection.prototype.fetch = function () {
        var _this = this;
        var co = {
            url: this._config.root,
            dataType: 'json'
        };
        $.ajax(co).done(function (s) {
            _this.list = s.books;
            _this._config.callback(s.books);
        }).fail(function (e) {
            console.log(e);
        });
    };
    Collection.prototype.update = function (data) {
        this._config.callback(data);
    };
    Collection.prototype.config = function (root, callback) {
        this._config = {
            root: root,
            callback: callback,
        };
        this.fetch();
    };
    Collection.prototype.add = function (modal) {
        this.list.push(modal);
        this.update(this.list);
    };
    Collection.prototype.filter = function (property, value) {
        debugger;
        return this.list.filter(function (e) {
            return e[property] == value;
        });
    };
    Collection.prototype.getList = function () {
        return this.list;
    };
    return Collection;
}());
var Book = (function (_super) {
    __extends(Book, _super);
    function Book(book) {
        _super.call(this);
        this.img_url = book.img_url;
        this.title = book.title;
        this.author_id = book.author_id;
        this.category = book.category;
    }
    return Book;
}(MODAL));
var Author = (function (_super) {
    __extends(Author, _super);
    function Author(author) {
        _super.call(this);
        this.first_name = author.first_name;
        this.last_name = author.last_name;
        this.author_id = author.author_id;
        this.books = author.books;
    }
    return Author;
}(MODAL));
